<template>
  <div>
    <div id="map">
      <v-row justify="end">
        <v-btn class="move__btn" @click="move()"> 
          <v-icon color="#3a4f4f">
            mdi-webcam
          </v-icon>
          악취제어 모니터링
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import dateUtil from "@/utils/custom/dateProperty.js";
import Apis from '@/api/apis';
// import axios from '../store/axios';

var setTime = null;
var rooms = ['R22035','R22037','R22034','R20044','R22039','R20043','R20041','R23026','R23027','R22053', 'R22052','R23028','R23029','R22051','R23030','R22049','R23031','R22050'];

export default {
  name: "SensorMap",
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    } ,() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
    setTime = setInterval(() => this.refresh(), 20 * 1000); // 20초 간격 갱신
  },
  mounted() {
    // axios.defaults.withCredentials = true;

    // // 예제 API 요청
    // axios.get('/some-endpoint')
    // .then(response => {
    //   console.log(response.data);
    // })
    // .catch(error => {
    //   console.error(error);
    // });
    
    // 카카오 객체가 있고, 맵 그리는 준비가 되어있다면 맵 실행
    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        // "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=d23283daf49e531d088248a1e4b69deb";  //bh key
        // "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=3c416d95761bb8465763d1091d21e308";     //my key
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=4799d9355ed066eb69a917b633b871e4";   //hyerin key

      document.head.appendChild(script);
    }
  },

  data() {
    return {

      map: null,
      markers: [],
    }
  },
  destroyed() {
    clearInterval(setTime);
  },
  methods:{
    move() {
      this.$router.push({
        name: "DeodorizerDviceMonitoring",
      });
    },

    async initMap(){
      // console.log(map_info);
      var mapContainer = document.getElementById('map'), // 지도를 표시할 div
        mapOption = {
          center: new kakao.maps.LatLng(36.5329000132009, 126.564982860129), // 지도의 중심좌표
          level: 4, // 지도의 확대 레벨
          mapTypeId : kakao.maps.MapTypeId.HYBRID // 지도종류 (기본 스카이뷰로 설정)
        };

      var map = new kakao.maps.Map(mapContainer, mapOption);

      var positions = [
        { 
          room_name: '6 | 주차장',
          room_cd: 'R22035',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22035',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                        }

                  // this.$route.path == "/SensorMap" ? setTime = setTimeout( () =>  this.refresh(), 60 * 1000) : "" ;

                  // this.$store.commit("resMessage",res.message);
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),

          latlng: new kakao.maps.LatLng(36.526674, 126.560952),
        },

        { 
          room_name: '8 | 반입조 외부',
          room_cd: 'R22037',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22037',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || null,
                          hum: res.data.hum.current || null,
                          co2: res.data.co2.current || null,
                          nh3: res.data.nh3.current || null,
                          h2s: res.data.h2s.current || null,
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526734, 126.561433),
        },

        { 
          room_name: '5 | 반입조 내부',
          room_cd: 'R22034',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22034',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526604, 126.561494),
        },

        { 
          room_name: '4 | 분뇨시설',
          room_cd: 'R20044',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R20044',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526899, 126.561662),
        },

        { 
          room_name: '3 | 액비조 남외곽',
          room_cd: 'R22039',
          data: // await this.getRoomData("R20042"),
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22039',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                        }

                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526488, 126.562614),
        },

        { 
          room_name: '1 | 액비조',
          room_cd: 'R20043',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R20043',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526781, 126.562853),
        },

        { 
          room_name: '2 | 액비조 동외곽',
          room_cd: 'R20041',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R20041',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.526777, 126.563058),
        },

        // 홍성원천마을
        { 
          room_name: '맹만호의집',
          room_cd: 'R23026',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23026',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.530600, 126.564000),
        },

        { 
          room_name: '박병예의집',
          room_cd: 'R23027',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23027',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.530440, 126.562000),
        },

        { 
          room_name: '송영수의집',
          room_cd: 'R22053',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22053',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.537041, 126.559805),
        },

        { 
          room_name: '송예균의집',
          room_cd: 'R22052',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22052',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.538908, 126.560751),
        },

        { 
          room_name: '송윤호의집',
          room_cd: 'R23028',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23028',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.53530, 126.5627),
        },

        { 
          room_name: '송천균의집',
          room_cd: 'R23029',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23029',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.539410, 126.562900),
        },

        { 
          room_name: '이광준의집',
          room_cd: 'R22051',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22051',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.535336, 126.562998),
        },

        { 
          room_name: '이광철의집',
          room_cd: 'R23030',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23030',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.529840, 126.560600),
        },

        { 
          room_name: '최종복의집',
          room_cd: 'R22049',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22049',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.529100, 126.560600),
        },

        { 
          room_name: '홍성원천마을',
          room_cd: 'R23031',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R23031',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.534512, 126.563091),
        },
        
        { 
          room_name: '황선상의집',
          room_cd: 'R22050',
          data:
                await Apis.roomMonitoringSummary({
                  room_cd: 'R22050',
                  stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
                } ,(res) => {  // 정상처리
                  return {
                          temp: res.data.temp.current || '',
                          hum: res.data.hum.current || '',
                          co2: res.data.co2.current || '',
                          nh3: res.data.nh3.current || '',
                          h2s: res.data.h2s.current || '',
                         }
                }).catch( (err) => {  // 개별 API 오류 처리 예제
                  console.log("monitoringSummary 호출 오류",err);
                }),
          latlng: new kakao.maps.LatLng(36.531479, 126.563547),
        },

    ]

    var imageSrc = require('@/assets/map_marker.svg'), // 마커이미지의 주소입니다
      imageSize = new kakao.maps.Size(50, 50); // 마커이미지의 크기입니다
      // imageOption = { offset: new kakao.maps.Point(0, 0) }; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.

    // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
    var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);

    // 마커를 생성합니다
      positions.forEach(function(pos) {
        // console.log(pos)
        var marker = new kakao.maps.Marker({
          map: map, // 마커를 표시할 지도
          position: pos.latlng, // 마커의 위치
          image: markerImage,
        });

        var customOverlay = new kakao.maps.CustomOverlay({
          position: pos.latlng,
          xAnchor: 0.5, // 커스텀 오버레이의 x축 위치입니다. 1에 가까울수록 왼쪽에 위치합니다. 기본값은 0.5 입니다
          yAnchor: 1.3, // 커스텀 오버레이의 y축 위치입니다. 1에 가까울수록 위쪽에 위치합니다. 기본값은 0.5 입니다
        });

                
        var content = document.createElement('div');
        // content.setAttribute( 'id', pos.room_cd );
        content.className = 'overlaybox';
        content.onclick = function() {
          customOverlay.setMap(null);
          customOverlay.setMap(map);
        };
        
        // 돈방 이름 (상단)
        var title = document.createElement('div');
        title.setAttribute( 'id', pos.room_cd );
        title.className = 'map-popup-title';


        var room_name = document.createElement('span');
        room_name.className = 'popup-name';
        room_name.appendChild(document.createTextNode(pos.room_name));
        title.appendChild(room_name);
        content.appendChild(title);

        // 닫기버튼
        var close = document.createElement('h3');
        close.className = 'close';
        title.appendChild(close);
        content.appendChild(title);

        close.onclick = function() {
          event.stopPropagation(); // 상위박스 클릭이벤트 방지
          customOverlay.setMap(null);
        };
        
        var value_content = document.createElement('div');
        // value_content.setAttribute( 'id', pos.room_cd + '_content');
        value_content.className = 'value_overlaybox';
        content.appendChild(value_content);

        // 온도
        if (pos.data.temp) {
          var value_temp = document.createElement('div');
          value_temp.className = 'map-popup-value';

          var temp_icon = document.createElement('span');
          temp_icon.className = 'temp_icon';
          value_temp.appendChild(temp_icon);
          value_content.appendChild(value_temp);

          // var temp_title = document.createElement('span');
          // temp_title.className = 'popup-value-title';
          // temp_title.appendChild(document.createTextNode('온도'));
          // value_temp.appendChild(temp_title);
          // value_content.appendChild(value_temp);

          var temp = document.createElement('span');
          temp.setAttribute( 'id', pos.room_cd + '_temp');
          temp.className = 'popup-value';
          temp.appendChild(document.createTextNode(pos.data.temp));
          value_temp.appendChild(temp);
          value_content.appendChild(value_temp);

          var temp_unit = document.createElement('span');
          temp_unit.className = 'popup-value-unit';
          temp_unit.appendChild(document.createTextNode('℃'));
          value_temp.appendChild(temp_unit);
          value_content.appendChild(value_temp);
        }
        // 습도
        if (pos.data.hum) {
          var value_hum = document.createElement('div');
          value_hum.className = 'map-popup-value';

          var hum_icon = document.createElement('span');
          hum_icon.className = 'hum_icon';
          value_hum.appendChild(hum_icon);
          value_content.appendChild(value_hum);
          
          // var hum_title = document.createElement('span');
          // hum_title.className = 'popup-value-title';
          // hum_title.appendChild(document.createTextNode('습도'));
          // value_hum.appendChild(hum_title);
          // value_content.appendChild(value_hum);

          var hum = document.createElement('span');
          hum.setAttribute( 'id', pos.room_cd + '_hum');
          hum.className = 'popup-value';
          hum.appendChild(document.createTextNode(pos.data.hum));
          value_hum.appendChild(hum);
          value_content.appendChild(value_hum);

          var hum_unit = document.createElement('span');
          hum_unit.className = 'popup-value-unit';
          hum_unit.appendChild(document.createTextNode('%'));
          value_hum.appendChild(hum_unit);
          value_content.appendChild(value_hum);
        }

        // 이산화탄소
        if (pos.data.co2) {
          var value_co2 = document.createElement('div');
          value_co2.className = 'map-popup-value';

          var co2_icon = document.createElement('span');
          co2_icon.className = 'co2_icon';
          value_co2.appendChild(co2_icon);
          value_content.appendChild(value_co2);
          
          // var co2_title = document.createElement('span');
          // co2_title.className = 'popup-value-title';
          // co2_title.appendChild(document.createTextNode('이산화탄소'));
          // value_co2.appendChild(co2_title);
          // value_content.appendChild(value_co2);

          var co2 = document.createElement('span');
          co2.setAttribute( 'id', pos.room_cd + '_co2');
          co2.className = 'popup-value';
          co2.appendChild(document.createTextNode(pos.data.co2));
          value_co2.appendChild(co2);
          value_content.appendChild(value_co2);

          var co2_unit = document.createElement('span');
          co2_unit.className = 'popup-value-unit';
          co2_unit.appendChild(document.createTextNode('ppm'));
          value_co2.appendChild(co2_unit);
          value_content.appendChild(value_co2);
        }

        // 암모니아
        if (pos.data.nh3) {
          var value_nh3 = document.createElement('div');
          value_nh3.className = 'map-popup-value';

          var nh3_icon = document.createElement('span');
          nh3_icon.className = 'nh3_icon';
          value_nh3.appendChild(nh3_icon);
          value_content.appendChild(value_nh3);
          
          // var nh3_title = document.createElement('span');
          // nh3_title.className = 'popup-value-title';
          // nh3_title.appendChild(document.createTextNode('암모니아'));
          // value_nh3.appendChild(nh3_title);
          // value_content.appendChild(value_nh3);

          var nh3 = document.createElement('span');
          nh3.setAttribute( 'id', pos.room_cd + '_nh3');
          nh3.className = 'popup-value';
          nh3.appendChild(document.createTextNode(pos.data.nh3));
          value_nh3.appendChild(nh3);
          value_content.appendChild(value_nh3);

          var nh3_unit = document.createElement('span');
          nh3_unit.className = 'popup-value-unit';
          nh3_unit.appendChild(document.createTextNode('ppm'));
          value_nh3.appendChild(nh3_unit);
          value_content.appendChild(value_nh3);
        }
        // 황화수소
        if (pos.data.h2s) {
          var value_h2s = document.createElement('div');
          value_h2s.className = 'map-popup-value';

          var h2s_icon = document.createElement('span');
          h2s_icon.className = 'h2s_icon';
          value_h2s.appendChild(h2s_icon);
          value_content.appendChild(value_h2s);
          
          // var h2s_title = document.createElement('span');
          // h2s_title.className = 'popup-value-title';
          // h2s_title.appendChild(document.createTextNode('황화수소'));
          // value_h2s.appendChild(h2s_title);
          // value_content.appendChild(value_h2s);

          var h2s = document.createElement('span');
          h2s.setAttribute( 'id', pos.room_cd + '_h2s');
          h2s.className = 'popup-value';
          h2s.appendChild(document.createTextNode(pos.data.h2s));
          value_h2s.appendChild(h2s);
          value_content.appendChild(value_h2s);

          var h2s_unit = document.createElement('span');
          h2s_unit.className = 'popup-value-unit';
          h2s_unit.appendChild(document.createTextNode('ppm'));
          value_h2s.appendChild(h2s_unit);
          value_content.appendChild(value_h2s);
        }

        kakao.maps.event.addListener(marker, 'click', function() {
          document.getElementById(pos.room_cd) ?  customOverlay.setMap(null) : customOverlay.setMap(map);
        });

        customOverlay.setContent(content);

      })

    },
    
    refresh(){
      this.$store.commit("resMessage","");    

      rooms.forEach(async (room) =>
        {
          if (document.getElementById(room)) {      //현재 팝업되어 있는 노드만 콜
            await Apis.roomMonitoringSummary({
              room_cd: room,
              stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
              } ,(res) => {  // 정상처리


                var old = document.getElementById(room + '_temp');
                if (old) {
                  var temp =  document.createElement('span');
                      temp.setAttribute( 'id', room + '_temp');
                      temp.className = 'popup-value';
                      temp.appendChild(document.createTextNode(res.data.temp.current || ''));

                      old.replaceChild(temp,old.firstChild);
                      // console.log("new",res.data.temp.current || '');
                      // console.log(this.$store.getters.resMessage);    
                      if (this.$store.getters.resMessage < "측정 : " + res.data.temp.chktime)  this.$store.commit("resMessage","측정 : " + res.data.temp.chktime);    
                } 

                old = document.getElementById(room + '_hum');
                if (old) {
                  var hum =  document.createElement('span');
                      hum.setAttribute( 'id', room + '_hum');
                      hum.className = 'popup-value';
                      hum.appendChild(document.createTextNode(res.data.hum.current || ''));

                      old.replaceChild(hum,old.firstChild);
                      // console.log("new",res.data.hum.current || '');    
                } 

                old = document.getElementById(room + '_co2');
                if (old) {
                  var co2 =  document.createElement('span');
                      co2.setAttribute( 'id', room + '_co2');
                      co2.className = 'popup-value';
                      co2.appendChild(document.createTextNode(res.data.co2.current || ''));

                      old.replaceChild(co2,old.firstChild);
                      // console.log("new",res.data.co2.current || '');    
                } 

                old = document.getElementById(room + '_nh3');
                if (old) {
                  var nh3 =  document.createElement('span');
                      nh3.setAttribute( 'id', room + '_nh3');
                      nh3.className = 'popup-value';
                      nh3.appendChild(document.createTextNode(res.data.nh3.current || ''));

                      old.replaceChild(nh3,old.firstChild);
                      // console.log("new",res.data.nh3.current || '');    
                } 

                old = document.getElementById(room + '_h2s');
                if (old) {
                  var h2s =  document.createElement('span');
                      h2s.setAttribute( 'id', room + '_h2s');
                      h2s.className = 'popup-value';
                      h2s.appendChild(document.createTextNode(res.data.h2s.current || ''));

                      old.replaceChild(h2s,old.firstChild);
                      // console.log("new",res.data.h2s.current || '');    
                } 
              }).catch( (err) => {  // 개별 API 오류 처리 예제
                console.log("roomMonitoringSummary 호출 오류",err);
            })
          }
        }
      );
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
#map {
  width: 100%;
  height: 95vh;
}
.move__btn{
  z-index:2;
  margin:30px;
  border-radius: 20px;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  color:#3a4f4f;
  letter-spacing: -0.74px;
  font-size:20px !important;
  height:50px !important;
  width:200px !important;
}
.button-group {
  margin: 10px 0px;
}
button {
  margin: 0 3px;
}
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  background: url('../../assets/value/overlay_close.png');
}
.close:hover {
  cursor: pointer;
}
.overlaybox{
  width:100%;
  /* min-width:200px; */
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  border-radius: 20px;
  transition:all 500ms linear;
  box-shadow: 0 5px 10px 0 rgb(255, 255, 255, 0.4);
}
.overlaybox:active{
  transition:all 500ms linear;
}
.overlaybox:after{
  content:'';
  position: absolute;
  margin-left: -11px;
  left: 50%; 
  bottom: -12px;
  width: 22px;
  height: 12px;
  background:url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png) no-repeat 0 bottom;
}
.value_overlaybox{
  padding:5px 5px 15px 5px;
}
.map-popup-title{
  border-radius: 20px 20px 0 0;
  background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);
  color:white;
  overflow: hidden;
  padding:10px 30px 10px 15px;
  width:100%;
  margin-right:20px;
}
.popup-name{
  font-size:1.3rem;
  font-weight:600;
  width:100%;
}

.map-popup-value{
  padding:5px 10px 0 7px;
  display:flex;
  justify-content: space-evenly; 
}
.popup-value-title{
  letter-spacing: -0.74px;
  font-weight:600;
}
.popup-value{
  width:85px;
  text-align: right;
  font-size:1.2rem;
  font-weight:500;
  letter-spacing: -0.75px;
}
.popup-value-unit{
  margin-left:5px;
  margin-top:4px;
  width:20px;
  font-size:0.8rem;
}
.popup-buttons{
  text-align: right;
}
.popup-button{
  background-color:#3a4f4f;
  padding:8px;
  color:white;
  border-radius: 10px;
  font-weight:600;
}
/* 아이콘 */
.temp_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 30px;
  height: 25px;
  background: url('../../assets/monitoring/temp.svg') no-repeat;
}
.hum_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 30px;
  height: 25px;
  background: url('../../assets/monitoring/humidity.svg') no-repeat;
}
.co2_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 30px;
  height: 25px;
  background: url('../../assets/monitoring/co2.svg') no-repeat;
}
.nh3_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 30px;
  height: 25px;
  background: url('../../assets/monitoring/nh3.svg') no-repeat;
}
.h2s_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 30px;
  height: 25px;
  background: url('../../assets/monitoring/h2s.svg') no-repeat;
}

</style>
